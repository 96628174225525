import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    // const logo = (
    //   <Box
    //     ref={ref}
    //     component="div"
    //     sx={{
    //       width: 40,
    //       height: 40,
    //       display: 'inline-flex',
    //       ...sx,
    //     }}
    //     {...other}
    //   >
    //     <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
    //       <defs>
    //         <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
    //           <stop offset="0%" stopColor={PRIMARY_DARK} />
    //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
    //         </linearGradient>

    //         <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
    //           <stop offset="0%" stopColor={PRIMARY_LIGHT} />
    //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
    //         </linearGradient>

    //         <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
    //           <stop offset="0%" stopColor={PRIMARY_LIGHT} />
    //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
    //         </linearGradient>
    //       </defs>

    //       <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
    //         <path
    //           fill="url(#BG1)"
    //           d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
    //         />
    //         <path
    //           fill="url(#BG2)"
    //           d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
    //         />
    //         <path
    //           fill="url(#BG3)"
    //           d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
    //         />
    //       </g>
    //     </svg>
    //   </Box>
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          color: theme.palette.primary.main, // Set color dynamically
          ...sx,
        }}
        {...other}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 192 192"
          fill="none"
        >
          <path
            d="M24 168L58.8 133.2M40 88C40 123.346 68.6538 152 104 152C139.346 152 168 123.346 168 88C168 52.6538 139.346 24 104 24C68.6538 24 40 52.6538 40 88Z"
            stroke="currentColor"
            strokeWidth="24"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="152.873"
            y1="125.652"
            x2="108.873"
            y2="95.6525"
            stroke="currentColor"
            strokeWidth="4"
          />
          <line
            x1="58.9825"
            y1="120.278"
            x2="102.983"
            y2="94.2781"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            d="M104 128C103.067 128 102.267 127.733 101.6 127.2C100.933 126.667 100.433 125.967 100.1 125.1C98.8333 121.367 97.2333 117.867 95.3 114.6C93.4333 111.333 90.8 107.5 87.4 103.1C84 98.7 81.2333 94.5 79.1 90.5C77.0333 86.5 76 81.6667 76 76C76 68.2 78.7 61.6 84.1 56.2C89.5667 50.7333 96.2 48 104 48C111.8 48 118.4 50.7333 123.8 56.2C129.267 61.6 132 68.2 132 76C132 82.0667 130.833 87.1333 128.5 91.2C126.233 95.2 123.6 99.1667 120.6 103.1C117 107.9 114.267 111.9 112.4 115.1C110.6 118.233 109.1 121.567 107.9 125.1C107.567 126.033 107.033 126.767 106.3 127.3C105.633 127.767 104.867 128 104 128ZM104 86C106.8 86 109.167 85.0333 111.1 83.1C113.033 81.1667 114 78.8 114 76C114 73.2 113.033 70.8333 111.1 68.9C109.167 66.9667 106.8 66 104 66C101.2 66 98.8333 66.9667 96.9 68.9C94.9667 70.8333 94 73.2 94 76C94 78.8 94.9667 81.1667 96.9 83.1C98.8333 85.0333 101.2 86 104 86Z"
            fill="currentColor"
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
